import { Container, Title, SectionContainer, InnerTitle, ColoredSection } from './styles'
import patientExerciseIcon from '../../images/PatientExerciseIcon.png'
import { Physical, Recommendations } from '../../types'
import Spinner from '../../components/Spinner'

interface Props {
  physicalActivity?: Physical
  recommendations?: Recommendations
}

function PatientPhysicalActivity({ physicalActivity, recommendations }: Props) {
  if (!physicalActivity || !recommendations) {
    return <Spinner />
  }

  return (
    <Container>
      <Title>
        <img height="40px" src={patientExerciseIcon} alt="patient nutrients icon" />
        <h2>Physical activity</h2>
      </Title>
      <SectionContainer>
        <InnerTitle main>
          <h4>Steps</h4>
          <h5>/day</h5>
        </InnerTitle>
        <InnerTitle>
          <h4>Goal</h4>
          <h4>{recommendations.steps.min}</h4>
        </InnerTitle>
        <ColoredSection bad={physicalActivity.steps.week < recommendations.steps.min}>
          <h4>In last week</h4>
          <h4>{physicalActivity.steps.week}</h4>
        </ColoredSection>
        <ColoredSection bad={physicalActivity.steps.month < recommendations.steps.min}>
          <h4>In last month</h4>
          <h4>{physicalActivity.steps.month}</h4>
        </ColoredSection>
      </SectionContainer>
      <SectionContainer>
        <InnerTitle main>
          <h4>Exercise</h4>
          <h5>mins/day</h5>
        </InnerTitle>
        <InnerTitle>
          <h4>Goal</h4>
          <h4>{recommendations.exercise.min}</h4>
        </InnerTitle>
        <ColoredSection bad={physicalActivity.exercise.week < recommendations.exercise.min}>
          <h4>In last week</h4>
          <h4>{physicalActivity.exercise.week}</h4>
        </ColoredSection>
        <ColoredSection bad={physicalActivity.steps.month < recommendations.steps.min}>
          <h4>In last month</h4>
          <h4>{physicalActivity.exercise.month}</h4>
        </ColoredSection>
      </SectionContainer>
      <SectionContainer>
        <InnerTitle main>
          <h4>Calories</h4>
          <h5>/day</h5>
        </InnerTitle>
        <InnerTitle>
          <h4>Goal</h4>
          <h4>{recommendations.calories.min}</h4>
        </InnerTitle>
        <ColoredSection bad={physicalActivity.calories.week < (recommendations.calories.min * 0.75)}>
          <h4>In last week</h4>
          <h4>{physicalActivity.calories.week}</h4>
        </ColoredSection>
        <ColoredSection bad={physicalActivity.calories.month < (recommendations.calories.min * 0.75)}>
          <h4>In last month</h4>
          <h4>{physicalActivity.calories.month}</h4>
        </ColoredSection>
      </SectionContainer>
    </Container>
  )
}

export default PatientPhysicalActivity