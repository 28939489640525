import { Patient, Recommendations } from './types'

export function fetchRecommendationsData(): Promise<Recommendations[]> {
    return fetch('/recommendations.json')
        .then(response => response.json())
        .catch(error => {
            console.log('ERROR ->', error);
            throw error;
        });
}

export function fetchPatientsData(): Promise<Patient[]> {
    return fetch('/patients.json')
        .then(response => response.json())
        .catch(error => {
            console.log('ERROR ->', error);
            throw error;
        });
}