import logo from "../../images/logo.png"
import profileIcon from "../../images/ProfileIcon.png"
import { Left, Right, Center, ProfileSection, Container, StyledText, StyledH1 } from "./styles"
import { Link } from "react-router-dom"


const TopBar = () => {

  return (
    <Container>
      <Left>
        <Link to="/">
          <img src={logo} alt='logo' height='70px' />
        </Link>
      </Left>
      <Center>
        <StyledH1>SILVERHEALTH</StyledH1>
      </Center>
      <Right>
        <ProfileSection to="/profile">
          <img src={profileIcon} alt='profile icon' height='30px' />
          <StyledText>Dr. Hansen</StyledText>
        </ProfileSection>
      </Right>
    </Container>
  )
}

export default TopBar