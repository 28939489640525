import patientInformationIcon from '../../images/PatientInformationIcon.png'
import { Overview } from '../../types'
import { Container, SectionContainer, Title } from './styles'
import Spinner from '../../components/Spinner'

interface Props {
  overview?: Overview
}

const PatientInformation = ({ overview }: Props) => {
  if (!overview) {
    return <Spinner />
  }

  return (
    <Container>
      <Title>
        <img height="40px" src={patientInformationIcon} alt="patient information icon" />
        <h2>Information</h2>
      </Title>
      <SectionContainer>
        <h5>Name</h5>
        <h4>{overview.name}</h4>
      </SectionContainer>
      <SectionContainer>
        <h5>Age</h5>
        <h4>{overview.age}</h4>
      </SectionContainer>
      <SectionContainer>
        <h5>Gender</h5>
        <h4>{overview.sex}</h4>
      </SectionContainer>
      <SectionContainer>
        <h5>Weight</h5>
        <h4>{overview.weight} kg</h4>
      </SectionContainer>
      <SectionContainer>
        <h5>Height</h5>
        <h4>{overview.height} cm</h4>
      </SectionContainer>
      <SectionContainer>
        <h5>Heart rate (resting)</h5>
        <h4>{overview.restingRate}</h4>
      </SectionContainer>
    </Container>
  )
}

export default PatientInformation