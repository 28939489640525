import { Container, Title, SectionContainer, InnerTitle, ColoredSection, Recommendation } from './styles'
import patientNutrientsIcon from '../../images/PatientNutrientsIcon.png'
import { Nutrients, Recommendations } from '../../types'
import Spinner from '../../components/Spinner'

interface Props {
  nutrients?: Nutrients
  recommendations?: Recommendations
}

function PatientsNutrients({ nutrients, recommendations }: Props) {
  if (!nutrients || !recommendations) {
    return <Spinner />
  }

  const isFatBad = nutrients.macro.fat < recommendations.macro.fat.min || nutrients.macro.fat > recommendations.macro.fat.max;
  const isFibreBad = nutrients.macro.fiber < recommendations.macro.fiber.min || nutrients.macro.fiber > recommendations.macro.fiber.max;
  const isProteinBad = nutrients.macro.protein < recommendations.macro.protein.min || nutrients.macro.protein > recommendations.macro.protein.max;
  const isCarbsBad = nutrients.macro.carbs < recommendations.macro.carbs.min || nutrients.macro.carbs > recommendations.macro.carbs.max;

  return (
    <Container>
      <Title>
        <img height="40px" src={patientNutrientsIcon} alt="patient nutrients icon" />
        <h2>Nutrients</h2>
      </Title>
      <SectionContainer>
        <InnerTitle>
          <h4>Macronutrients</h4>
          <h5>% calories/day</h5>
        </InnerTitle>
        {isProteinBad ?
        <>
          <ColoredSection bad={isProteinBad}>
            <h4>Protein</h4>
            <h4>{nutrients.macro.protein}{recommendations.macro.protein.unit}</h4>
          </ColoredSection>
          <Recommendation>
            Recommendation: {recommendations.macro.protein.min}-{recommendations.macro.protein.max}{recommendations.macro.protein.unit}
          </Recommendation>
        </> : null
        }
        {isCarbsBad ?
        <>
          <ColoredSection bad={isCarbsBad}>
            <h4>Carbs</h4>
            <h4>{nutrients.macro.carbs}{recommendations.macro.carbs.unit}</h4>
          </ColoredSection>
          <Recommendation>
            Recommendation: {recommendations.macro.carbs.min}-{recommendations.macro.carbs.max}{recommendations.macro.carbs.unit}
          </Recommendation>
        </> : null
        }
        {isFibreBad ?
        <>
          <ColoredSection bad={isFibreBad}>
            <h4>Fiber</h4>
            <h4>{nutrients.macro.fiber}{recommendations.macro.fiber.unit}</h4>
          </ColoredSection>
          <Recommendation>
            Recommendation: {recommendations.macro.fiber.min}-{recommendations.macro.fiber.max}{recommendations.macro.fiber.unit}
          </Recommendation>
        </> : null
        }
        {isFatBad ?
        <>
          <ColoredSection bad={nutrients.macro.fat < recommendations.macro.fat.min || nutrients.macro.fat > recommendations.macro.fat.max}>
            <h4>Fat</h4>
            <h4>{nutrients.macro.fat}{recommendations.macro.fat.unit}</h4>
          </ColoredSection>
          <Recommendation>
            Recommendation: {recommendations.macro.fat.min}-{recommendations.macro.fat.max}{recommendations.macro.fat.unit}
          </Recommendation>
        </> : null
        }
      </SectionContainer>
      <SectionContainer>
        <InnerTitle>
          <h4>Micronutrients</h4>
          <h5>/day</h5>
        </InnerTitle>
        <ColoredSection bad={nutrients.macro.fiber < recommendations.macro.fiber.min}>
          <h4>Vitamin A</h4>
          <h4>{nutrients.micro.vitaminA} {recommendations.micro.vitaminA.unit}</h4>
        </ColoredSection>
        <Recommendation>
          Recommendation: 700{/* {recommendations.micro.vitaminA.min} */} {recommendations.micro.vitaminA.unit}
        </Recommendation>
        <ColoredSection bad={nutrients.micro.calcium < recommendations.micro.calcium.min}>
          <h4>Vitamin B1</h4> {/* calcium */}
          <h4>{nutrients.micro.calcium} {recommendations.micro.calcium.unit}</h4>
        </ColoredSection>
        <Recommendation>
          Recommendation: 1.1{/* {recommendations.micro.calcium.min} */} {recommendations.micro.calcium.unit}
        </Recommendation>
        <ColoredSection bad={nutrients.micro.iodine < recommendations.micro.iodine.min}>
          <h4>Vitamin D</h4>  {/* iodine */}
          <h4>{nutrients.micro.iodine} {recommendations.micro.iodine.unit}</h4>
        </ColoredSection>
        <Recommendation>
          Recommendation: 20{/* {recommendations.micro.iodine.min} */} {recommendations.micro.iodine.unit}
        </Recommendation>
        {/* <ColoredSection bad={nutrients.micro.vitaminB2 < recommendations.micro.vitaminB2.min}>
          <h4>Riboflavin (B2)</h4>
          <h4>{nutrients.micro.vitaminB2} {recommendations.micro.vitaminB2.unit}</h4>
        </ColoredSection>
        <Recommendation>
          Recommendation: {recommendations.micro.vitaminB2.min} {recommendations.micro.vitaminB2.unit};
        </Recommendation> */}
      </SectionContainer>
    </Container>
  )
}

export default PatientsNutrients