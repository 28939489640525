import { useLocation, useParams } from 'react-router-dom';
import { Container, NavBarItem } from './styles';


const PatientNavBar = () => {
  const location = useLocation();
  const params = useParams();
  return (
    <Container>
      <NavBarItem to={`/patients/${params.cpr}/overview`} active={location.pathname.endsWith("overview")}>
        Patient Overview
      </NavBarItem>
      <NavBarItem to={`/patients/${params.cpr}/nutrition`} active={location.pathname.endsWith("nutrition")}>
        Nutrition
      </NavBarItem>
      <NavBarItem to={`/patients/${params.cpr}/physical-activity`} active={location.pathname.endsWith("activity")}>
        Physical activity
      </NavBarItem>
      <NavBarItem to={`/patients/${params.cpr}/analysis`} active={location.pathname.endsWith("analysis")}>
        Analysis
      </NavBarItem>
    </Container>
  )
}

export default PatientNavBar