import PatientItem from '../../components/PatientItem'
import { Container } from './styles'
import { Patient } from '../../types'
import { fetchPatientsData } from '../../loadData'
import { useState, useEffect } from 'react'

const Patients = () => {
  const [patients, setPatients] = useState<Patient[]>([]);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const fetchedPatients = await fetchPatientsData();
        setPatients(fetchedPatients);
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    };
    fetchPatients();
  }, []);

  return (
    <Container>
      {patients.map((patient: Patient) => {
        return (<PatientItem key={patient?.overview.cpr} patient={patient} />)
      })}
    </Container>
  )
}

export default Patients;