import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${(props) => props.theme.palette.background.light};
  gap: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  & h5,
  & h4 {
    margin: 0;
  }
`

export const Title = styled.div`
  display: flex;
  gap: 16px;
  color: ${(props) => props.theme.palette.grey.dark};
  align-items: center;
  margin-bottom: 4px;
  & > h2 {
    margin: 0;
  }
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${(props) => props.theme.palette.grey.dark};
  & > h4 {
    font-weight: 400;
  }
`
