import PatientInformation from '../../components/PatientInformation'
import { Container, TopBar, Content } from './styles'
import patientIcon from "../../images/PatientIcon.png"
import PatientMicroMacroNutrients from '../../components/PatientMicroMacroNutrients'
import PatientNavBar from '../../components/PatientNavBar'
import Spinner from '../../components/Spinner'
import { useParams } from 'react-router-dom'
import { Patient, Recommendations } from '../../types'
import { fetchPatientsData, fetchRecommendationsData } from '../../loadData'
import { useState, useEffect } from 'react'

const PatientNutrition = () => {
  const params = useParams();
  const [patients, setPatients] = useState<Patient[]>([]);
  const [recommendations, setRecommendations] = useState<Recommendations>();
  const [loading, setLoading] = useState<boolean>(true);
  const [retry, setRetry] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchPatientsData(), fetchRecommendationsData()])
      .then(([fPatients, fRecommendations]) => {
        setPatients(fPatients);
        const patient: Patient = fPatients.find((patient: Patient) => patient?.overview.cpr === params.cpr) as Patient
        const recommended: Recommendations = fRecommendations.find((recommendations: Recommendations) => recommendations.minAge <= patient?.overview.age && recommendations.maxAge >= patient?.overview.age && recommendations.sex === patient?.overview.sex) as Recommendations
        setRecommendations(recommended ?? fRecommendations[fRecommendations.length - 1])
      })
      .catch(() => setRetry(true))
      .finally(() => setLoading(false))
  }, [params.cpr]);

  if (loading) {
    return <Spinner />
  }

  if (retry) {
    return <div>Failed to fetch data. <button onClick={() => setRetry(false)}>Retry</button></div>
  }

  const patient: Patient = patients.find((patient: Patient) => patient?.overview.cpr === params.cpr) as Patient

  return (
    <Container>
      <TopBar>
        <img height="28px" src={patientIcon} alt="patient icon" />
        <h3>Patient:</h3>
        <h3>{patient?.overview.name}</h3>
      </TopBar>
      <PatientNavBar />
      <Content>
        <PatientInformation overview={patient?.overview} />
        <PatientMicroMacroNutrients recommendations={recommendations} cpr={patient?.overview.cpr} nutrients={patient?.nutrients} />
      </Content>
    </Container>
  )
}

export default PatientNutrition
