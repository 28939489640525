import { Container, SidebarItem, StyledIcon, StyledText, SidebarItemBottom } from './styles'
import allPatientsIconWhite from '../../images/AllPatientsIcon-white.png'
import riskyPatientsIconWhite from '../../images/RiskyPatientsIcon-white.png'
import archivedPatientsIconWhite from '../../images/ArchivedPatientsIcon-white.png'


const Sidebar = () => {
  return (
    <Container>
      <SidebarItem to="/">
        <StyledIcon src={allPatientsIconWhite} height='26px'></StyledIcon>
        <StyledText>All Patients</StyledText>
      </SidebarItem>
      <SidebarItem to="/risky-patients">
        <StyledIcon src={riskyPatientsIconWhite} height='30px'></StyledIcon>
        <StyledText>Risky Patients</StyledText>
      </SidebarItem>
      <SidebarItem to="/archived-patients">
        <StyledIcon src={archivedPatientsIconWhite} height='26px'></StyledIcon>
        <StyledText>Archived Patients</StyledText>
      </SidebarItem>
      {/* <SidebarItemBottom to="/archived-patients">
        <StyledIcon src={archivedPatientsIconWhite} height='26px'></StyledIcon>
        <StyledText>Archived Patients</StyledText>
      </SidebarItemBottom> */}
    </Container>
  )
}

export default Sidebar