import styled, { keyframes } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SpinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
`;

const SpinnerRing = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid rgb(241, 124, 146);
  border-radius: 50%;
  animation: ${SpinnerAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(241, 124, 146) transparent transparent transparent;
`;

const Spinner = () => {
  return (
    <Wrapper>
        <SpinnerWrapper>
            <SpinnerRing />
        </SpinnerWrapper>
    </Wrapper>
  );
};

export default Spinner;