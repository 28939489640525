import Sidebar from '../Sidebar'
import TopBar from '../TopBar'
import { Container, Content } from './styles'
type Props = { children: React.ReactNode }

const Layout = (props: Props) => {
  return (
    <Container>
      <TopBar/>
      <Sidebar />
      <Content>
        {props.children}
      </Content>
    </Container>
  )
}

export default Layout