interface Theme {
  palette: {
    main: {
      primary: string
      secondary: string
    }
    white: string
    background: {
      light: string
      dark: string
    }
    grey: {
      light: string
      dark: string
    }
    risk: {
      red: string
      yellow: string
      green: string
    }
  }
}

export const theme: Theme = {
  palette: {
    main: {
      primary: "#F96D89",
      secondary: "#F17C92",
    },
    white: "#fff",
    background: {
      light: "#FEFBF6",
      dark: "#F8F3EC",
    },
    grey: {
      light: "#DADADA",
      dark: "#8D8C8A",
    },
    risk: {
      red: "#E75757",
      yellow: "#FACF37",
      green: "#8EE757",
    },
  },
}
