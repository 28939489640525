import { Container, StatusBox } from './styles'
import { Patient } from '../../types'

type Props = {
  patient: Patient
}

const PatientItem = (props: Props) => {
  return (
    <Container to={`/patients/${props.patient.overview.cpr}/overview`}>
      <h3>{props.patient.overview.name}</h3>
      <h3>{props.patient.overview.age}</h3>
      <h3>{props.patient.overview.address}</h3>
      <StatusBox status={props.patient.overview.status} />
    </Container>
  )
}

export default PatientItem