import { ColoredSection, Container, InnerContainer, InnerTitle } from './styles'
import { Nutrients, Recommendations } from '../../types'
import { useState } from 'react';
import Spinner from '../../components/Spinner'

interface Props {
  cpr?: string,
  nutrients?: Nutrients
  recommendations?: Recommendations
}

const PatientMicroMacroNutrients = ({ cpr, nutrients, recommendations }: Props) => {
  const [macroImageExists, setMacroImageExists] = useState(true);
  const [microImageExists, setMicroImageExists] = useState(true);

  const handleMacroImageError = () => {
    setMacroImageExists(false);
  };

  const handleMicroImageError = () => {
    setMicroImageExists(false);
  };

  if (!nutrients || !recommendations) {
    return <Spinner />
  }

  return (
    <Container>
      <InnerContainer>
        <InnerTitle>
          <h5>Macronutrients</h5>
          <h5>% cal/day</h5>
          <h5>recommendation</h5>
        </InnerTitle>
        <ColoredSection bad={nutrients.macro.protein < recommendations.macro.protein.min || nutrients.macro.protein > recommendations.macro.protein.max}>
          <h4>Protein</h4>
          <h4>{nutrients.macro.protein}{recommendations.macro.protein.unit}</h4>
          <h4>{recommendations.macro.protein.min}-{recommendations.macro.protein.max}{recommendations.macro.protein.unit}</h4>
        </ColoredSection>
        <ColoredSection bad={nutrients.macro.fat < recommendations.macro.fat.min || nutrients.macro.fat > recommendations.macro.fat.max}>
          <h4>Fat</h4>
          <h4>{nutrients.macro.fat}{recommendations.macro.fat.unit}</h4>
          <h4>{recommendations.macro.fat.min}-{recommendations.macro.fat.max}{recommendations.macro.fat.unit}</h4>
        </ColoredSection>
        <ColoredSection bad={nutrients.macro.carbs < recommendations.macro.carbs.min || nutrients.macro.carbs > recommendations.macro.carbs.max}>
          <h4>Carbs</h4>
          <h4>{nutrients.macro.carbs}{recommendations.macro.carbs.unit}</h4>
          <h4>{recommendations.macro.carbs.min}-{recommendations.macro.carbs.max}{recommendations.macro.carbs.unit}</h4>
        </ColoredSection>
        <ColoredSection bad={nutrients.macro.fiber < recommendations.macro.fiber.min || nutrients.macro.fiber > recommendations.macro.fiber.max}>
          <h4>Fiber</h4>
          <h4>{nutrients.macro.fiber}{recommendations.macro.fiber.unit}</h4>
          <h4>{recommendations.macro.fiber.min}-{recommendations.macro.fiber.max}{recommendations.macro.fiber.unit}</h4>
        </ColoredSection>
        {macroImageExists ? (
          <img src={`/images/${cpr}/macro_bar.png`} onError={handleMacroImageError} height="450px" alt="macro_bar" style={{ marginTop: "0.5rem", objectFit: "contain" }} />
        ) : (
          <img src="/images/GENERIC/macro_bar.png" height="450px" alt="macro_bar" style={{ marginTop: "0.5rem", objectFit: "contain" }} />
        )}
      </InnerContainer>
      <InnerContainer>
        <InnerTitle>
          <h5>Micronutrients</h5>
          <h5>/day</h5>
          <h5>recommendation</h5>
        </InnerTitle>
        {"110799-XXXX" === cpr ? (
          <>
            {/* <ColoredSection bad={nutrients.micro.vitaminA < recommendations.micro.vitaminA.min}>
              <h4>Vitamin A</h4>
              <h4>{nutrients.micro.vitaminA} {recommendations.micro.vitaminA.unit}</h4>
              <h4>700{recommendations.micro.vitaminA.unit}</h4>
            </ColoredSection>
            <ColoredSection bad={nutrients.micro.calcium < recommendations.micro.calcium.min}>
              <h4>Vitamin B1</h4>
              <h4>{nutrients.micro.calcium} {recommendations.micro.calcium.unit}</h4>
              <h4>1.1{recommendations.micro.calcium.unit}</h4>
            </ColoredSection>
            <ColoredSection bad={nutrients.micro.iodine < recommendations.micro.iodine.min}>
              <h4>Vitamin D</h4>
              <h4>{nutrients.micro.iodine} {recommendations.micro.iodine.unit}</h4>
              <h4>20{recommendations.micro.iodine.unit}</h4>
            </ColoredSection> */}
          </>
        ) : ("140338-XXXX" === cpr ? (
          <>
          <ColoredSection bad={nutrients.micro.vitaminA < recommendations.micro.vitaminA.min}>
              <h4>Vitamin A</h4>
              <h4>{nutrients.micro.vitaminA} {recommendations.micro.vitaminA.unit}</h4>
              <h4>700{recommendations.micro.vitaminA.unit}</h4>
            </ColoredSection>
            <ColoredSection bad={nutrients.micro.calcium < recommendations.micro.calcium.min}>
              <h4>Vitamin B1</h4>
              <h4>{nutrients.micro.calcium} {recommendations.micro.calcium.unit}</h4>
              <h4>1.1{recommendations.micro.calcium.unit}</h4>
            </ColoredSection>
            <ColoredSection bad={true}>
              <h4>Niacin </h4>
              <h4>{nutrients.micro.vitaminB2} {recommendations.micro.vitaminB2.unit}</h4> 
              <h4>18{recommendations.micro.vitaminB2.unit}</h4>
            </ColoredSection>
            <ColoredSection bad={nutrients.micro.iodine < recommendations.micro.iodine.min}>
              <h4>Vitamin B6</h4>
              <h4>{nutrients.micro.iodine} {recommendations.micro.iodine.unit}</h4>
              <h4>1.5{recommendations.micro.iodine.unit}</h4>
            </ColoredSection>
          </>
        ) : (
          <>
          <ColoredSection bad={true}>
              <h4>Vitamin A</h4>
              <h4>{nutrients.micro.vitaminA} {recommendations.micro.vitaminA.unit}</h4>
              <h4>700{/* {recommendations.micro.vitaminA.min} */}{recommendations.micro.vitaminA.unit}</h4>
            </ColoredSection>
            <ColoredSection bad={true}>
              <h4>Iodine</h4>
              <h4>{nutrients.micro.iodine} {recommendations.micro.iodine.unit}</h4>
              <h4>{recommendations.micro.iodine.min} {recommendations.micro.iodine.unit}</h4>
            </ColoredSection>
          </>
        ))}

        {/* <ColoredSection bad={nutrients.micro.vitaminB2 < recommendations.micro.vitaminB2.min}>
          <h4>Riboflavin(B2)</h4>
          <h4>{nutrients.micro.vitaminB2} {recommendations.micro.vitaminB2.unit}</h4>
          <h4>{recommendations.micro.vitaminB2.min}{recommendations.micro.vitaminB2.unit}</h4>
        </ColoredSection> */}
        {microImageExists ? (
          <img src={`/images/${cpr}/micro_bar.png`} onError={handleMicroImageError} height="450px" alt="micro_bar" />
        ) : (
          <img src="/images/GENERIC/micro_bar.png" height="450px" alt="micro_bar" />
        )}
      </InnerContainer>
    </Container>
  )
}

export default PatientMicroMacroNutrients