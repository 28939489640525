import { Container, InnerTitle } from './styles'

interface Props {
  cpr?: string,
}

const PatientAnalysisDetails = ({ cpr }: Props) => {
  return (
    <Container>
        <InnerTitle>
          <h4>Analysis</h4>
        </InnerTitle>
        <img src="/images/GENERIC/analysis.png" width="100%" alt="analysis" />
    </Container>
  )
}

export default PatientAnalysisDetails