import PatientInformation from '../../components/PatientInformation'
import { Container, TopBar, Content } from './styles'
import patientIcon from "../../images/PatientIcon.png"
import PatientNutrients from '../../components/PatientNutrients'
import PatientPhysicalActivity from '../../components/PatientPhysicalActivity'
import PatientNavBar from '../../components/PatientNavBar'
import { useParams } from 'react-router-dom'
import { Patient, Recommendations } from '../../types'
import { fetchPatientsData, fetchRecommendationsData } from '../../loadData'
import { useState, useEffect } from 'react';

const PatientOverview = () => {
  const params = useParams();
  const [patients, setPatients] = useState<Patient[]>([]);
  const [allRecommendations, setAllRecommendations] = useState<Recommendations[]>([]);
  const [recommendations, setRecommendations] = useState<Recommendations | undefined>(undefined);

  useEffect(() => {
    fetchPatientsData().then((fPatients: Patient[]) => setPatients(fPatients));
    fetchRecommendationsData().then((fRecommendations: Recommendations[]) => setAllRecommendations(fRecommendations));
  }, []);

  useEffect(() => {
    const patient: Patient = patients.find((patient: Patient) => patient?.overview.cpr === params.cpr) as Patient
    const recs: Recommendations = allRecommendations.find((recommendations: Recommendations) => recommendations.minAge <= patient?.overview.age && recommendations.maxAge > patient?.overview.age && recommendations.sex === patient?.overview.sex) as Recommendations
    if (recs !== undefined) { setRecommendations(recs) }
    else { setRecommendations(allRecommendations[allRecommendations.length - 1]) }
  }, [params, patients, allRecommendations])

  return (
    <Container>
      <TopBar>
        <img height="28px" src={patientIcon} alt="patient icon" />
        <h3>Patient:</h3>
        <h3>{patients.find((patient: Patient) => patient?.overview.cpr === params.cpr)?.overview.name}</h3>
      </TopBar>
      <PatientNavBar />
      <Content>
        <PatientInformation overview={patients.find((patient: Patient) => patient?.overview.cpr === params.cpr)?.overview} />
        <PatientNutrients recommendations={recommendations} nutrients={patients.find((patient: Patient) => patient?.overview.cpr === params.cpr)?.nutrients} />
        <PatientPhysicalActivity recommendations={recommendations} physicalActivity={patients.find((patient: Patient) => patient?.overview.cpr === params.cpr)?.physical} />
      </Content>
    </Container>
  )
}

export default PatientOverview