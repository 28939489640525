import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: ${(props) => props.theme.palette.background.light};
  gap: 24px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  & h5,
  & h4 {
    margin: 0;
  }
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  color: ${(props) => props.theme.palette.grey.dark};
  & > h4 {
    font-weight: 400;
  }
`

export const InnerTitle = styled.div<{ main?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.palette.grey.dark};
  border-bottom: ${(props) =>
    props.main ? `2px solid ${props.theme.palette.grey.dark}` : "none"};
  margin-bottom: 8px;
`

export const ColoredSection = styled.div<{ bad?: boolean }>`
  display: flex;
  justify-content: space-between;
  & > h4 {
    &:nth-of-type(2) {
      color: ${(props) =>
        props.bad
          ? props.theme.palette.risk.red
          : props.theme.palette.risk.green};
    }
  }
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${(props) => props.theme.palette.grey.dark};
  & > h4 {
    font-weight: 400;
  }
  padding-bottom: 32px;
`