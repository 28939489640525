import { SectionContainer, Container, ColoredSection, InnerContainer, InnerTitle } from './styles'
import { Physical, Recommendations } from '../../types'
import { useState } from 'react';
import Spinner from '../../components/Spinner'

interface Props {
  cpr?: string,
  physicalActivity?: Physical
  recommendations?: Recommendations
}

const PatientPhysicalActivityDetails = ({ cpr, physicalActivity, recommendations }: Props) => {
  const [imageExists, setImageExists] = useState(true);

  const handleImageError = () => {
    setImageExists(false);
  };

  if (!physicalActivity || !recommendations) {
    return <Spinner />
  }

  return (
    <Container>
      <InnerContainer>
        <SectionContainer>
          <InnerTitle main>
            <h4>Steps</h4>
            <h5>/day</h5>
          </InnerTitle>
          <InnerTitle>
            <h4>Goal</h4>
            <h4>{recommendations.steps.min}</h4>
          </InnerTitle>
          <ColoredSection bad={physicalActivity.steps.week < recommendations.steps.min}>
            <h4>In last week</h4>
            <h4>{physicalActivity.steps.week}</h4>
          </ColoredSection>
          <ColoredSection bad={physicalActivity.steps.month < recommendations.steps.min}>
            <h4>In last month</h4>
            <h4>{physicalActivity.steps.month}</h4>
          </ColoredSection>
        </SectionContainer>
        <SectionContainer>
          <InnerTitle main>
            <h4>Exercise</h4>
            <h5>mins/day</h5>
          </InnerTitle>
          <InnerTitle>
            <h4>Goal</h4>
            <h4>{recommendations.exercise.min}</h4>
          </InnerTitle>
          <ColoredSection bad={physicalActivity.exercise.week < recommendations.exercise.min}>
            <h4>In last week</h4>
            <h4>{physicalActivity.exercise.week}</h4>
          </ColoredSection>
          <ColoredSection bad={physicalActivity.exercise.month < recommendations.exercise.min}>
            <h4>In last month</h4>
            <h4>{physicalActivity.exercise.month}</h4>
          </ColoredSection>
        </SectionContainer>
        <SectionContainer>
          <InnerTitle main>
            <h4>Calories</h4>
            <h5>/day</h5>
          </InnerTitle>
          <InnerTitle>
            <h4>Goal</h4>
            <h4>{recommendations.calories.min}</h4>
          </InnerTitle>
          <ColoredSection bad={physicalActivity.calories.week < (recommendations.calories.min * 0.75)}>
            <h4>In last week</h4>
            <h4>{physicalActivity.calories.week}</h4>
          </ColoredSection>
          <ColoredSection bad={physicalActivity.calories.month < (recommendations.calories.min * 0.75)}>
            <h4>In last month</h4>
            <h4>{physicalActivity.calories.month}</h4>
          </ColoredSection>
        </SectionContainer>
      </InnerContainer>
      <InnerContainer>
        <InnerTitle>
          <h4>Energy Balance</h4>
        </InnerTitle>
        {imageExists ? (
          <img src={`/images/${cpr}/kcal_over_time.png`} onError={handleImageError} height="350px" alt="kcal_over_time" />
        ) : (
          <img src="/images/GENERIC/kcal_over_time.png" height="350px" alt="kcal_over_time" />
        )}
      </InnerContainer>
    </Container>
  )
}

export default PatientPhysicalActivityDetails