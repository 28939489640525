import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background-color: ${(props) => props.theme.palette.background.light};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  & h5,
  & h4 {
    margin: 0;
  }
`
export const InnerTitle = styled.div<{ main?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.palette.grey.dark};
  border-bottom: ${(props) =>
    props.main ? `2px solid ${props.theme.palette.grey.dark}` : "none"};
  margin-bottom: 8px;
`