import { NavLink } from "react-router-dom"
import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 54px 40px 1fr;
  gap: 12px;
`

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${(props) => props.theme.palette.grey.dark};
`

export const InnerNavBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 26px;
  align-items: center;
`

export const NavBarItem = styled(NavLink)<{ active?: boolean }>`
  text-align: center;
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.main.primary
      : props.theme.palette.grey.light};
  height: 100%;
  width: 100%;
  max-width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.active ? props.theme.palette.white : props.theme.palette.grey.dark};
  font-weight: ${(props) => (props.active ? 600 : 400)};
  border-radius: 100px;
`

export const Content = styled.div`
  padding: 16px 32px;
  display: grid;
  grid-template-columns: 2fr 6fr;
  gap: 32px;
`
