export type Patient = {
  overview: Overview
  nutrients: Nutrients
  physical: Physical
}

export type Nutrients = {
  micro: NutrientsMicro
  macro: NutrientsMacro
}

export type NutrientsMacro = {
  fiber: number
  fat: number
  protein: number
  carbs: number
}

export type NutrientsMicro = {
  vitaminA: number
  vitaminB2: number
  calcium: number
  iodine: number
}

export type Overview = {
  name: string
  age: number
  cpr: string
  sex: string
  weight: string
  height: string
  restingRate: string
  address: string
  status: Status
}

export type Physical = {
  steps: Counters
  exercise: Counters
  calories: Counters
}

export type Counters = {
  week: number
  month: number
}

export type Recommendations = {
  sex: string
  minAge: number
  maxAge: number
  micro: RecommendationsMicro
  macro: RecommendationsMacro
  steps: Count
  exercise: Count
  calories: Count
}

export type Count = {
  min: number
}

export type RecommendationsMacro = {
  fiber: Limits
  fat: Limits
  protein: Limits
  carbs: Limits
}

export type RecommendationsMicro = {
  vitaminA: Limits
  vitaminB2: Limits
  calcium: Limits
  iodine: Limits
}

export type Limits = {
  min: number
  max: number
  unit?: string
}

export enum Status {
  GOOD = "GOOD",
  OKAY = "OKAY",
  BAD = "BAD",
}
