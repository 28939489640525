import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-area: topBar;
  background-color: ${({ theme }) => theme.palette.background.light};
  padding: 16px;
`

export const Left = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
`

export const Center = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
`

export const Right = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
`

export const ProfileSection = styled(Link)`
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`

export const StyledText = styled.h3`
  color: ${({ theme }) => theme.palette.main.primary};
  margin:0;
  `
export const StyledH1 = styled.h1`
  font-family: "MontserratExtraLight";
  color: ${({ theme }) => theme.palette.main.primary};
  font-weight: inherit
`