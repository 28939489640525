import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 54px 40px 1fr;
  gap: 12px;
`

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${(props) => props.theme.palette.grey.dark};
`

export const Content = styled.div`
  padding: 16px 32px;
  display: grid;
  grid-template-columns: 2fr 6fr;
  gap: 32px;
`
