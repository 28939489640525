import { Link } from "react-router-dom"
import styled from "styled-components"
import { Status } from '../../types'

export const Container = styled(Link)`
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 140px;
  align-items: center;
  height: 80px;
  padding-left: 16px;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  max-width: 1000px;
  margin: 20px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  background-color: ${({ theme }) => theme.palette.background.light};
  color: ${({ theme }) => theme.palette.grey.dark};
`

export const StatusBox = styled.div<{ status?: Status }>`
  height: 100%;
  background-color: ${(props) =>
    props.status === Status.BAD
      ? props.theme.palette.risk.red
      : props.status === Status.OKAY
      ? props.theme.palette.risk.yellow
      : props.theme.palette.risk.green}; };
`
