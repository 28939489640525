import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Patients from "./pages/Patients";
import Layout from "./components/Layout";
import './index.css';
import RiskyPatients from "./pages/RiskyPatients";
import { ThemeProvider } from "styled-components";
import PatientOverview from "./pages/PatientOverview";
import PatientNutrition from "./pages/PatientNutrition";
import PatientAnalysis from "./pages/PatientAnalysis";
import PatientPhysicalActivity from "./pages/PatientPhysicalActivity";
import { theme } from "./theme";

const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Layout>
          <Routes>
            <Route path="/" element={<Patients />} />
            <Route path="/risky-patients" element={<RiskyPatients />} />
            <Route path="/patients/:cpr/overview" element={<PatientOverview />} />
            <Route path="/patients/:cpr/nutrition" element={<PatientNutrition />} />
            <Route path="/patients/:cpr/physical-activity" element={<PatientPhysicalActivity />} />
            <Route path="/patients/:cpr/analysis" element={<PatientAnalysis />} />
          </Routes>
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);
root.render(<App />);

export default App;