import { Container, TopBar, Content } from './styles'
import patientIcon from "../../images/PatientIcon.png"
import PatientInformation from '../../components/PatientInformation'
import PatientAnalysisDetails from '../../components/PatientAnalysisDetails'
import PatientNavBar from '../../components/PatientNavBar'
import { useParams } from 'react-router-dom'
import { Patient, Recommendations } from '../../types'
import { useEffect, useState } from 'react'
import { fetchPatientsData, fetchRecommendationsData } from '../../loadData'
import Spinner from '../../components/Spinner'

const PatientAnalysis = () => {
  const params = useParams();
  const [patient, setPatient] = useState<Patient>();
  const [recommendations, setRecommendations] = useState<Recommendations>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    Promise.all([fetchPatientsData(), fetchRecommendationsData()])
      .then(([fPatients, fRecommendations]) => {
        const loadedPatient: Patient= fPatients.find((patient: Patient) => patient?.overview.cpr === params.cpr) as Patient;
        setPatient(loadedPatient);
        const loadedRecommendations: Recommendations | undefined = fRecommendations.find((recommendations: Recommendations) => recommendations.minAge <= loadedPatient?.overview.age && recommendations.maxAge > loadedPatient?.overview.age && recommendations.sex === loadedPatient?.overview.sex);
        setRecommendations(loadedRecommendations || fRecommendations[fRecommendations.length - 1]);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, [params.cpr]);

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Container>
      <TopBar>
        <img height="28px" src={patientIcon} alt="patient icon" />
        <h3>Patient:</h3>
        <h3>{patient?.overview.name}</h3>
      </TopBar>
      <PatientNavBar />
      <Content>
        <PatientInformation overview={patient?.overview} />
        <PatientAnalysisDetails />
      </Content>
    </Container>
  )
}

export default PatientAnalysis;
