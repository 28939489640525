import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-template-rows: 100px 1fr;
  background-color: ${({ theme }) => theme.palette.background.dark};
  width: 100%;
  height: 100%;
  grid-template-areas:
    "topBar topBar"
    "sideBar content";
`

export const Content = styled.div`
  width: 100%;
  max-width: 1400px;
  grid-area: content;
  justify-self: center;
  background-color: ${({ theme }) => theme.palette.background.dark};
`
