import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.main.secondary};
  grid-area: sideBar;
  `

export const SidebarItem = styled(NavLink)`
  padding-left: 16px;
  display: grid;
  grid-template-columns: 46px 1fr;
  height: 56px;
  border-bottom: 2px solid #f7f7f7;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.main.secondary};
`

export const SidebarItemBottom = styled(NavLink)`
  padding-left: 16px;
  display: grid;
  grid-template-columns: 46px 1fr;
  height: 56px;
  border-bottom: 2px solid #f7f7f7;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.main.secondary};
`

export const StyledIcon = styled.img`
  justify-self: center;
`

export const StyledText = styled.h3`
  color: ${({ theme }) => theme.palette.white};
`